export const Data = [
    {
        id: 1,
        img: "https://actionsupplychain.com/assets/admin/B1-PNG.png",
        name: "Warehousing, Storage & Distribution",
        t1: "Project Management &",
        t2: "Implementation Support",


    },
    {
        id: 2,
        img: "https://actionsupplychain.com/assets/admin/B2.jpg",
        name: "Transportation & Integrated Logistics",
        t1: "Primary Transportation",
        t2: "Secondary Transportation",


    },
    {
        id: 3,
        img: "https://actionsupplychain.com/assets/admin/B3.jpeg",
        name: "Product Management Solutions",
        t1: "Data Management  &",
        t2: "Order Management",


    },
    
    {
        id: 4,
        img: "https://actionsupplychain.com/assets/admin/B4-480x260.png",
        name: "Inventory Management",
        t1: "Project Management &",
        t2: "Implementation Support",

    },
    {
        id: 5,
        img: "https://actionsupplychain.com/assets/admin/B5-480x260.jpg",
        name: "Supply Chain  Design Management",
        t1: "Project Management &",
        t2: "Implementation Support",

    },
]
