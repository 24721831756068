import React from 'react'
import './Gallery.css'

import Footer from '../Footer/Footer'
const Gallery = () => {
  return (
    <div className='gallery'>
      <h1>Gallery</h1>
      <div className='gallery-content'>
        <p>BEST SOLUTIONS AND SERVICES</p>
        <h2>Our focus is to always keep improving ourselves</h2>
      </div>
      {/* <div className='IMAGE'>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/Pic 2.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/13.jpeg')} alt="pic-1" />
      
          <p></p>
        </div>
        
      </div>
      <div className='IMAGE'>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/10.jpeg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/Pic 3.jpg')} alt="pic-1" />
        
          <p></p>
        </div>
        
      </div> */}
      <div className='IMAGES'>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/13.jpeg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/10.jpeg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Pic 3.jpg')} alt="pic-1" />
          <p></p>
        </div>
      </div>
      
      <div className='IMAGES'>
        <div className='img1'>
          <img src={require('../../Assests/Gallery Pics/4.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/2.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/16.jpg')} alt="pic-1" />
          <p></p>
        </div>
      </div>
      
      <div className='IMAGES'>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/7.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/6.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/17.jpeg')} alt="pic-1" />
          <p></p>
        </div>
      </div>
      
      <div className='IMAGES'>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/8.jpeg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/15.jpg')} alt="pic-1" />
          <p></p>
        </div>
        <div className='img1'>
        <img src={require('../../Assests/Gallery Pics/1.jpg')} alt="pic-1" />
          <p></p>
        </div>
      </div>
      <Footer/>
    </div>
  )
}

export default Gallery