import React from 'react'
import './Footer.css'
import { Link, NavLink } from 'react-router-dom'
const Footer = () => {
  return (
    <div class="footer">
      <div class="Map-links">
        <div class="map">
          <h5>Address</h5>
          <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d951.3606724327212!2d78.3982795695008!3d17.486368778094302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDI5JzEwLjkiTiA3OMKwMjMnNTYuMSJF!5e0!3m2!1sen!2sin!4v1684496793465!5m2!1sen!2sin" width="350" height="200"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <div class="address">
            <b>Action Supply Chain Solutions Pvt. Ltd.</b>
            <p>
              MIG-II 622 ,3rd Floor,Prasanthi Nilayam, <br />
              Road No-1,Opposite Community Hall,KPHB Colony , <br />
              Kukatpally, Hyderabad, Telangana 500085
            </p>
          </div>
        </div>
        <div class="links">
          <div>
            <h5>Links</h5>
          </div>
          <div class="sitelinks">
            <NavLink to='/'>Home</NavLink>
            <NavLink to='/aboutus'>About Us</NavLink>
            <Link to='/whatwedo'>What We Do</Link>
            <Link to='/whoweserve'>Who We Serve</Link>
            <Link to='/gallery'>Gallery</Link>
            <Link to='/careers'>Careers</Link>
          </div>
        </div>
      </div>
      <div class="media">
        <div class="socialmedia">
          <h5>Social Media</h5>
          <div class="icons">
            <a href="facebook.com" class="fa fa-facebook"></a>
            <span>Follow Us On Facebook</span>
          </div>
          <div class="icons">
            <a href="instagram.com" class="fa fa-instagram"></a>
            <span>Follow Us On Instagram</span>
          </div>
          <div class="icons">
            <a href="linkedin.com" class="fa fa-linkedin"></a>
            <span>Follow Us On Linkedin</span>
          </div>
        </div>
        <div class="contactus">
          <h5>Contact Us</h5>
          <div class="phone">
            <i class="fa Fa fa-phone"></i>
      
            <a style={{color:"black"}} href="tel:+91 8341510555"> &nbsp; &nbsp;8341510555</a>
          </div>
          <div class="mail">
            <i class="fa fa-envelope"></i>
            
            <a style={{color:"black"}} href="mailto:info@actionsupplychain.com">&nbsp; &nbsp; info@actionsupplychain.com</a>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Footer;